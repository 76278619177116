<template>
  <div class="information-warning">
    <x-table :columns="table.columns" :data="table.data" :loading="table.loading" :config="config" @search="search" @page-change="pageChange" @page-size-change="pageSizeChange">
    </x-table>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  name: '',
  data() {
    return {
      table: {
        columns: [
          {
            title: '养殖场名称',
            key: 'farmName',
          },
          {
            title: '设备名称',
            key: 'deviceName',
          },
          {
            title: '预警类型',
            key: 'alarmType',
            render: (h, { row }) => {
              if (row.alarmType == 2) {
                return <span>设备预警</span>
              } else if (row.alarmType == 4) {
                return <span>设施配置预警</span>
              } else if (row.alarmType == 5) {
                return <span>存储量预警</span>
              } else {
                return <span>--</span>
              }
            },
          },
          {
            title: '预警等级',
            key: 'leaveName',
          },
          {
            title: '预警内容',
            key: 'content',
          },
          {
            title: '状态',
            key: 'statusName',
          },
        ],
        data: [],
        loading: false,
      },
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },
      search_data: {},
    }
  },
  computed: {
    config() {
      let config = {
        filter: {
          width: 200,
          filterBox: [
            {
              conditionName: '预警类型',
              component: 'select',
              field: 'alarmType',
              data: [
                {
                  alarmType: 2,
                  alarmTypeName: '设备预警',
                },
                {
                  alarmType: 4,
                  alarmTypeName: '设施配置预警',
                },
                {
                  alarmType: 5,
                  alarmTypeName: '存储量预警',
                },
              ],
              defaultValue: '',
              parameterField: 'alarmType',
              showField: 'alarmTypeName',
            },
            {
              conditionName: '预警状态',
              component: 'select',
              field: 'status',
              data: [
                {
                  status: 0,
                  statusName: '未处理',
                },
                {
                  status: 1,
                  statusName: '已处理',
                },
              ],
              defaultValue: '',
              parameterField: 'status',
              showField: 'statusName',
            },
          ],
        },
        page: this.page,
      }
      return config
    },
  },
  methods: {
    search(data) {
      this.page.pageNo = 1
      this.search_data = data
      this.getList()
    },
    pageChange(pageNo) {
      this.page.pageNo = pageNo
      this.getList()
    },
    pageSizeChange(pageSize) {
      this.page.pageSize = pageSize
      this.getList()
    },
    getList() {
      this.table.loading = true
      this.$post(this.$api.PRODUCE_MAPINFO.WARNING, {
        pageNo: this.page.pageNo,
        pageSize: this.page.pageSize,
        ...this.search_data,
      })
        .then((res) => {
          this.table.data = res.list
          this.page.total = +res.total
        })
        .finally(() => {
          this.table.loading = false
        })
    },
  },
  mounted() {
    this.getList()
  },
}
</script>

<style lang="less" scoped>
.information-warning {
  width: 100%;
  height: 100%;
}
</style>